/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { createElement, forwardRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import {
	resolveProps,
	marginable,
	fontable,
} from "../../../styles/styledComponentsMixins";

const Block = styled(
	({
		innerRef,
		relative,
		full,
		flex,
		flexWrap,
		column,
		justifyStart,
		justifyCenter,
		justifyBetween,
		justifyAround,
		justifyEnd,
		alignBaseline,
		alignCenter,
		alignEnd,
		alignStart,
		textRight,
		textCenter,
		inline,
		fullWidth,
		fullHeight,
		width,
		height,
		oyAuto,
		minWidth,
		maxWidth,
		textNowrap,
		hide,
		overflow,
		overflowX,
		overflowY,
		addGrid,
		lastElement,
		groupEvents,
		removePaddingOnMobile,
		withRowGap,
		withColumnGap,
		...props
	}) =>
		createElement("div", {
			ref: innerRef,
			...resolveProps(props, marginable, fontable),
		})
)`
	${({ relative }) =>
		relative &&
		css`
			position: relative;
		`}}

	${({ absolute }) =>
		absolute &&
		css`
			position: absolute;
			inset: 0;
		`}}

	${({ flex }) => {
		return flex
			? css`
					display: flex;
					${({ column }) => css`
						flex-direction: ${column ? `column` : `row`};
					`}
					${({ flexWrap }) =>
						css`
							flex-wrap: ${flexWrap ? "wrap" : "nowrap"};
						`}
				${({ justifyStart }) =>
						justifyStart &&
						css`
							justify-content: start;
						`}
				${({ justifyCenter }) =>
						justifyCenter &&
						css`
							justify-content: center;
						`}
				${({ justifyBetween }) =>
						justifyBetween &&
						css`
							justify-content: space-between;
						`}
				${({ justifyAround }) =>
						justifyAround &&
						css`
							justify-content: space-around;
						`}
				${({ justifyEnd }) =>
						justifyEnd &&
						css`
							justify-content: flex-end;
						`}
				${({ alignBaseline }) =>
						alignBaseline &&
						css`
							align-items: baseline;
						`}
				${({ alignEnd }) =>
						alignEnd &&
						css`
							align-items: flex-end;
						`}
				${({ alignStart }) =>
						alignStart &&
						css`
							align-items: flex-start;
						`}
				${({ alignCenter }) =>
						alignCenter &&
						css`
							align-items: center;
						`}
				${({ withRowGap }) =>
						withRowGap &&
						css`
							row-gap: var(--size-grid);
						`}
				${({ withColumnGap }) =>
						withColumnGap &&
						css`
							column-gap: var(--size-grid);
						`}
			  `
			: null;
	}}

	${({ textRight }) =>
		textRight &&
		css`
			text-align: right;
		`}

	${({ textCenter }) =>
		textCenter &&
		css`
			text-align: center;
		`}
	${({ flex, inline }) =>
		!flex &&
		css`
			display: ${inline ? "inline-block" : "block"};
		`}


	${({ full }) =>
		full &&
		css`
			flex: 1;
		`}
	${({ fullWidth, width }) =>
		(fullWidth || width) &&
		css`
			width: ${fullWidth
				? "100%"
				: Number.isInteger(width)
				? `${width}px`
				: width};
		`}

	${({ fullHeight, height }) =>
		(fullHeight || height) &&
		css`
			height: ${fullHeight
				? "100%"
				: Number.isInteger(height)
				? `${height}px`
				: height};
		`}

	${({ overflow }) =>
		overflow &&
		css`
			overflow: ${overflow};
		`}

	${({ overflowY }) =>
		overflowY &&
		css`
			overflow-y: ${overflowY};
		`}

	${({ overflowX }) =>
		overflowX &&
		css`
			overflow-x: ${overflowX};
		`}

	${({ minWidth }) =>
		minWidth &&
		css`
			min-width: ${Number.isInteger(minWidth)
				? `${minWidth}px`
				: minWidth};
		`}
		${({ maxWidth }) =>
			maxWidth &&
			css`
				max-width: ${Number.isInteger(maxWidth)
					? `${maxWidth}px`
					: maxWidth};
			`}


	${({ maxHeight }) =>
		maxHeight &&
		css`
			max-height: ${Number.isInteger(maxHeight)
				? `${maxHeight}px`
				: maxHeight};
		`}
	${({ hide }) =>
		hide &&
		css`
			display: none;
		`}}

	${marginable.css}
	${fontable.css}
`;

Block.defaultProps = {
	...marginable.defaultProps,
	inline: false,
};

Block.propTypes = {
	...marginable.propTypes,
	inline: PropTypes.bool,
	flex: PropTypes.bool,
	flexWrap: PropTypes.bool,
	column: PropTypes.bool,
	full: PropTypes.bool,
	justifyStart: PropTypes.bool,
	justifyBetween: PropTypes.bool,
	justifyCenter: PropTypes.bool,
	justifyEnd: PropTypes.bool,
	alignBaseline: PropTypes.bool,
	textRight: PropTypes.bool,
	textCenter: PropTypes.bool,
	children: PropTypes.node,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	fullWidth: PropTypes.bool,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	fullHeight: PropTypes.bool,
};

Block.displayName = "Block";

const Blockx = forwardRef((props, ref) => <Block innerRef={ref} {...props} />);
export default Blockx;
